import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './LoanLegalDisclaimer.scss';
import LenderData from './LenderData';
import LoanData from './LoanData';
import LoanCreditData from './LoanCreditData';
import LoanLegalDisclaimerPoint from './LoanLegalDisclaimerPoint';
import LegalData from './LegalData';
import LoanExtraData from './LoanExtraData';
import IconButton from '@material-ui/core/IconButton';
import { CancelIcon } from '../../skin';
import config from '../../shared/config';

const LoanLegalDisclaimer = ({ visible, user, onClose, amount, days, amountInterests, tae }) => {
  const tin = (user?.interest_rate || config.interestRate) * 100 * 365;

  return (
    <Dialog open={visible} keepMounted maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <div className="LoanLegalDisclaimer-title">
          <h2>INFORMACIÓN NORMALIZADA EUROPEA SOBRE EL CRÉDITO AL CONSUMO</h2>
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <ol>
          <li>
            <LoanLegalDisclaimerPoint
              idx="1"
              title="Identidad y datos de contacto del Prestamista"
            />
            <LenderData />
          </li>
          <li>
            <LoanLegalDisclaimerPoint
              idx="2"
              title="Descripción de las características principales del producto de crédito"
            />
            <LoanData amount={amount} days={days} amountInterests={amountInterests} />
          </li>
          <li>
            <LoanLegalDisclaimerPoint idx="3" title="Costes del crédito" />
            <LoanCreditData tin={tin} tae={tae || 0} />
          </li>
          <li>
            <LoanLegalDisclaimerPoint idx="4" title="Otros aspectos jurídicos importantes" />
            <LegalData dni={user?.dni} />
          </li>
          <li>
            <LoanLegalDisclaimerPoint
              idx="5"
              title="Información adicional en caso de comercialización a distancia de servicios financieros"
            />
            <LoanExtraData />
          </li>
        </ol>
      </DialogContent>
    </Dialog>
  );
};

export default LoanLegalDisclaimer;
