import React from 'react';
import './LoanLegalDisclaimer.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const LenderData = ({}) => (
  <Table>
    <TableBody className={'LoanLegalDisclaimer-table'}>
      <TableRow>
        <TableCell>Sociedad:</TableCell>
        <TableCell>NBQ FUND ONE, S.L.U</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>CIF:</TableCell>
        <TableCell>B66128695</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Dirección:</TableCell>
        <TableCell>Calle Jordi Girona, 29, P. 1. 08034, Barcelona (barcelona). España</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Nº de teléfono de Atención al Cliente:</TableCell>
        <TableCell>
          <a className="Link" href="tel:900 83 11 63">
            900 83 11 63
          </a>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>63Correo electrónico:</TableCell>
        <TableCell>
          <a className="Link" href="mailto:info@quebueno.es">
            info@quebueno.es
          </a>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Página web:</TableCell>
        <TableCell>
          <a className="Link" href="www.quebueno.es">
            www.quebueno.es
          </a>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default LenderData;
