import React from 'react';
import './LoanLegalDisclaimer.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const LoanExtraData = ({}) => {
  return (
    <Table>
      <TableBody className={'LoanLegalDisclaimer-tableBorder'}>
        <TableRow>
          <TableCell>a) Relativa al Prestamista</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Datos de la inscripción del Prestamista en el Registro Mercantil</TableCell>
          <TableCell>
            INSCRIPCIÓN : previo examen y calificación del documento que antecede por el Registrador
            que suscribe, de conformidad con lo previsto en los artículos 18 del Código de Comercio
            y 6 del Reglamento del Registro Mercantil, en la fecha abajo indicada, ha quedado
            INSCRITO en el TOMO 43970, FOLIO 183, HOJA B 443262, INSCRIPCION 21.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Autoridad de supervisión</TableCell>
          <TableCell>
            La actividad del Prestamista no está sometida a la regulación y supervisión del Banco de
            España.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>b) Relativa al contrato de préstamo</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Ejercicio del derecho de desistimiento</TableCell>
          <TableCell>
            <p>
              El Prestatario dispone de 14 días naturales para desistir del contrato, debiendo
              notificárselo al Prestamista por correo postal a Calle Jordi Girona, 29, P. 1. 08034,
              Barcelona (barcelona). España, o a la dirección de correo electrónico
              info@quebueno.es, indicando su nombre, apellidos, N.º de DNI/NIE y N.º de contrato.
            </p>
            <p>
              El Prestatario deberá reembolsar el capital prestado y el interés acumulado sobre
              dicho capital entre la fecha de disposición del crédito y la fecha de reembolso del
              capital dentro de los treinta días naturales siguientes al envío de dicha
              notificación.
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Cláusula sobre la legislación aplicable al contrato de préstamo y/o tribunal competente
          </TableCell>
          <TableCell>
            El contrato se regirá por la legislación española. El Tribunal competente para cualquier
            reclamación judicial derivada del mismo será el del domicilio del Prestatario.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Régimen lingüístico</TableCell>
          <TableCell>
            La información y los términos contractuales se facilitan en castellano. Durante la
            vigencia del contrato y con el consentimiento del Prestatario, la comunicación entre el
            Prestatario y el Prestamista se realizará en castellano.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>c) Relativa al recurso</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Existencia y acceso a los procedimientos extrajudiciales de reclamación y recurso
          </TableCell>
          <TableCell>
            NBQ FUND ONE, S.L.U. tiene establecido un servicio de Atención al Cliente al que dirigir
            cualquier queja o reclamación. Asimismo, se encuentra adherida al sistema extrajudicial
            de controversias de Confianza Online.
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LoanExtraData;
