import React, { useState, useEffect } from 'react';
import { getCookie, removeCookie } from '../shared/libs/cookies';
import Layout from '../components/Layout';
import Home from '../components/Home';
import LoanLegalDisclaimer from '../components/LoanLegalDisclaimer';
import * as apiService from '../shared/api.service';
import useGTM from '../shared/customHooks/useGTM';
import { createPixel } from '../shared/pixels';
import DiscountPopup from '../components/Discount/DiscountPopup';

const Index = () => {
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);
  const [discountPopupVisible, setDiscountPopupVisible] = useState(false);
  const [calculatedData, setCalculatedData] = useState({
    amount: 300,
    days: 30,
    amountInterests: 408,
    tae: 0,
  });
  useGTM({ pageTitle: 'Inicio', pageContent: 'form', viewContent: 'home', slug: 'calculator' });

  useEffect(() => {
    const getUser = async () => {
      const { data, status } = await apiService.getUser();
      if (status === 200 || status === 304) {
        setUser(data);
      } else {
        removeCookie('jwt');
      }
    };

    if (getCookie('jwt')) {
      getUser();
    }
    createPixel();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const cookie = getCookie('gatsby-gdpr-google-tagmanager');
      if (Boolean(cookie)) {
        clearInterval(interval);
      }
    }, 500);
  }, []);

  const toggleVisible = () => setVisible((old) => !old);
  const toggleDiscount = () => setDiscountPopupVisible((old) => !old);

  return (
    <Layout user={user} handleDiscount={toggleDiscount}>
      <>
        <LoanLegalDisclaimer
          visible={visible}
          user={user}
          onClose={toggleVisible}
          {...calculatedData}
        />
        <DiscountPopup visible={discountPopupVisible} setVisible={toggleDiscount} />
        <Home
          user={user}
          openModal={toggleVisible}
          onRecalculateData={setCalculatedData}
          handleDiscount={toggleDiscount}
        />
      </>
    </Layout>
  );
};

export default Index;
