import React from 'react';
import './LoanLegalDisclaimer.scss';

const LoanLegalDisclaimerPoint = ({ idx, title }) => (
  <div className="LoanLegalDisclaimer-item">
    <p>{idx}.</p>
    <p>{title}</p>
  </div>
);

export default LoanLegalDisclaimerPoint;
