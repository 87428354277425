import React from 'react';
import './LoanLegalDisclaimer.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const LegalData = ({ dni = 'DNI CLIENTE' }) => {
  return (
    <Table>
      <TableBody className={'LoanLegalDisclaimer-tableBorder'}>
        <TableRow>
          <TableCell>
            Derecho de desistimiento del contrato
            <p>
              El Prestatario tiene derecho a desistir del contrato de préstamo en el plazo de 14
              días naturales.
            </p>
          </TableCell>
          <TableCell>
            <p>
              El Prestatario podrá desistir del contrato de préstamo en el plazo de 14 días
              naturales desde la fecha de aprobación de la Solicitud de Préstamo, sin coste
              adicional, dirigiendo una notificación por correo postal o electrónico al Prestamista.
            </p>
            <p>
              El Prestatario deberá reembolsar el capital prestado y el interés acumulado sobre
              dicho capital entre la fecha de disposición del préstamo y la fecha de reembolso del
              capital dentro de los treinta días naturales siguientes al envío de dicha
              notificación.
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Reembolso anticipado
            <br /> El Prestatario tendrá derecho a reembolsar anticipadamente el importe total o
            parcial del préstamo en cualquier momento.
          </TableCell>
          <TableCell>
            <p className="underline">Con Tarjeta Bancaria:</p>
            <p>
              El Prestatario puede devolver el préstamo antes de vencimiento pagando intereses
              únicamente por el tiempo que ha tenido el préstamo. Para ello ha de dirigirse a la
              sección de Mi Cuenta en www.QueBueno.es e identificarse como usuario con su DNI y
              contraseña. Seleccionar "Devolver ahora" e inmediatamente el Prestamista realizará un
              cargo en la tarjeta de débito del Prestatario que éste verá reflejado en su cuenta
              bancaria inmediatamente.
            </p>
            <br />
            <br />
            <p className="underline">Con transferencia Bancaria::</p>
            <p>
              Adicionalmente, puede realizar una transferencia en la cuenta bancaria del
              Prestamista. Los datos para realizar la transferencia son los siguientes:
            </p>
            <p>Cuenta en la que hacer la Transferencia:</p>
            <p>ES62 0081 0603 06 0001450255 del Banco Sabadell</p>
            <p>
              <span className="li">-</span> Beneficiario de la transferencia "NBQ Fund One, S.L.U."
            </p>
            <p>
              <span className="li">-</span> Concepto de la transferencia {dni}
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Consulta de una base de datos</TableCell>
          <TableCell>
            <p>
              El Prestamista podrá recabar información relativa a los antecedentes crediticios y
              posiciones de riesgo del Prestatario de entidades prestadoras de servicios de
              información sobre solvencia patrimonial y crédito (en particular, del Servicio de
              Crédito de Asnef-Equifax y de Experian/Badexcug).
            </p>
            <p>
              El Prestamista podrá acceder a los datos bancarios del prestatario siempre que
              disponga de su autorización
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Derecho a la obtención de un proyecto del contrato de crédito</TableCell>
          <TableCell>
            <p>
              El Prestatario tendrá derecho, previa petición, a recibir una copia gratuita del
              proyecto de contrato. Esta disposición no resultará de aplicación si en el momento de
              la solicitud el Prestamista no estuviese dispuesto a celebrar el contrato con el
              Prestatario.
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Período durante el cual el Prestamista está vinculado por la información precontractual
          </TableCell>
          <TableCell>
            <p>
              La información precontractual tendrá un plazo de validez de 2 días naturales desde su
              entrega.
            </p>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LegalData;
