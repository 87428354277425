import React from 'react';
import './LoanLegalDisclaimer.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const LoanData = ({ amount = 300, days = 30, amountInterests = 408 }) => {
  const formatAmount = (amnt) =>
    new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(amnt);

  const formatDays = (d) => (d === 1 ? `${d} día` : `${d} días`);

  return (
    <Table>
      <TableBody className={'LoanLegalDisclaimer-tableBorder'}>
        <TableRow>
          <TableCell>Tipo de crédito</TableCell>
          <TableCell>Préstamo.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Importe total del crédito.</TableCell>
          <TableCell>{formatAmount(amount)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Condiciones que rigen la disposición de fondos.
            <p>Es decir, cómo y cuándo el consumidor obtendrá el dinero.</p>
          </TableCell>
          <TableCell>
            Una vez perfeccionado el contrato, el Prestamista efectuará una transferencia por el
            importe del préstamo a la cuenta bancaria titularidad del Prestatario indicada en la
            Solicitud de Préstamo.
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Duración del contrato de crédito.
            <p>Con sujeción a los términos y condiciones específicas del contrato de préstamo.</p>
          </TableCell>
          <TableCell>
            {`${formatDays(
              days
            )} naturales a contar desde la aprobación de la Solicitud de Préstamo. Cuando la fecha de vencimiento coincida con un día inhábil, el pago deberá efectuarse el día hábil inmediatamente siguiente.`}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Plazos y orden en que se realizarán los pagos a plazos</TableCell>
          <TableCell>
            <p>{`El Prestatario deberá satisfacer mediante un único pago la devolución del importe del préstamo, esto es, ${formatAmount(
              amount
            )}, más los intereses y/o gastos, que ascienden a ${formatAmount(
              amountInterests
            )}.`}</p>
            <p>
              El Prestatario abonará dichas cantidades (i) mediante ingreso en cuenta o
              transferencia bancaria; (ii) a través de cargo en tarjeta de crédito o débito; (iii)
              por cualquier otro medio de pago que el Prestamista pueda poner a disposición del
              Prestatario en un futuro.
            </p>
            <p>
              Los pagos que el Prestatario haya de satisfacer en virtud del Contrato se entenderán
              realizados una vez que hayan sido recibidos en la cuenta bancaria del Prestamista.
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Importe total a pagar
            <p>
              Es decir, el importe del capital prestado más los intereses y posibles gastos
              relacionados con el crédito.
            </p>
          </TableCell>
          <TableCell>{formatAmount(amountInterests)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LoanData;
