import React from 'react';
import './LoanLegalDisclaimer.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import taeImg from '../../skin/images/landing/taeFormula.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const LoanCreditData = ({ tin = 0, tae = 0 }) => {
  const formatAmount = (amnt) =>
    new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(amnt);

  return (
    <Table>
      <TableBody className={'LoanLegalDisclaimer-tableBorder'}>
        <TableRow>
          <TableCell>Tipo deudor</TableCell>
          <TableCell>{`Tipo de Interés Nominal (TIN) ${tin}%`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <p>Tasa anual equivalente (TAE):</p>
            <p>
              Es el coste total del préstamo expresado en forma de porcentaje anual del importe
              total del crédito.
            </p>
            <p>La TAE permite comparar distintas ofertas.</p>
          </TableCell>
          <TableCell>
            <p>TAE {tae}%</p>
            <LazyLoadImage src={taeImg} alt="tae formula" />
            <p className="legend">siendo:</p>

            <p className="legend">
              <span className="formula">M</span> = el número de créditos pagados por el prestamista
            </p>
            <p className="legend">
              <span className="formula">l</span> = el número de secuencia de los créditos pagados
              por el prestamista (declinación)
            </p>
            <p className="legend">
              <span className="formula">
                S<sub>l</sub>
              </span>{' '}
              = el crédito (declinación) en el periodo <span className="formula">l</span>
            </p>
            <p className="legend">
              <span className="formula">N</span> = el número total de créditos pagados por el
              prestatario
            </p>
            <p className="legend">
              <span className="formula">k</span> = el número de secuencia de los créditos pagados
              por el prestatario (reembolso)
            </p>
            <p className="legend">
              <span className="formula">
                A<sub>k</sub>
              </span>{' '}
              = el crédito (reembolso) del periodo <span className="formula">k</span> , y{' '}
              <span className="formula">
                t<sub>k</sub>
              </span>{' '}
              es el intervalo, expresado en años y fracciones anuales, entre la fecha del primer
              crédito y la fecha del crédito <span className="formula">l</span> o{' '}
              <span className="formula">k</span> . ({' '}
              <span className="formula">
                t<sub>1</sub>
              </span>
              = 0)
            </p>
            <p className="legend">
              La Tasa Anual Equivalente se ha calculado de acuerdo con la fórmula contenida en el
              artículo 32 y el anexo I de la Ley 16/2011, de 24 de junio, de Contratos de Crédito al
              Consumo.
            </p>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Póliza y servicios accesorios</TableCell>
          <TableCell>No es obligatorio.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Costes relacionados</TableCell>
          <TableCell>No.</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            Costes en caso de pagos atrasados
            <p>
              La no realización de un pago podrá acarrear graves consecuencias y dificultar la
              obtención de un préstamo.
            </p>
          </TableCell>
          <TableCell>
            <p>{`1% diario + ${formatAmount(30)} fijos desde la fecha de
            incumplimiento + ${formatAmount(
              10
            )} adicionales durante el período de recobro anterior a la inclusión en fichero de solvencia
            `}</p>
            <p>
              En caso de impago, los datos del Prestatario podrán ser incluidos en los ficheros
              comunes de solvencia patrimonial y crédito (en particular, de Asnef-Equifax y
              Badexcug).
            </p>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LoanCreditData;
